.clear{
    margin: 0;
    padding: 0;
}

#main-content .container:nth-child(odd) {
    background: #dedede; 
}

#main-content .container:nth-child(even) {
    background: #fefefe;
}

#main-content .container{
    padding : 20px;
}

.notification-container{
    top: auto !important;
    bottom: 0px !important;
}